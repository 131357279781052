import React from 'react';

const CaseStudyDevelopment = ({ className, ...delegated }: { className?: string }) => (
  <svg
    className={className}
    {...delegated}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.4408 29.5342H5.89666C5.76744 29.529 5.64511 29.474 5.55554 29.3807C5.46598 29.2874 5.41616 29.1631 5.41626 29.0337V13.0197C5.41626 12.3349 5.68827 11.678 6.17254 11.1938C6.6568 10.7095 7.31374 10.4375 7.9986 10.4375H31.3589C32.0437 10.4375 32.7004 10.7095 33.1847 11.1938C33.669 11.678 33.9412 12.3349 33.9412 13.0197V29.0337C33.9412 29.1665 33.8885 29.2937 33.7946 29.3876C33.7008 29.4814 33.5735 29.5342 33.4408 29.5342ZM6.3971 28.5333H32.9003V13.0598C32.8951 12.642 32.727 12.2427 32.4316 11.9472C32.1362 11.6518 31.7366 11.4835 31.3188 11.4783H7.95852C7.53725 11.4783 7.13278 11.6443 6.83303 11.9403C6.53327 12.2363 6.36229 12.6386 6.35702 13.0598L6.3971 28.5333Z"
      fill="#1A1A1A"
    />
    <path
      d="M31.439 27.5346H7.89839C7.76566 27.5346 7.63841 27.4818 7.54456 27.388C7.45071 27.2941 7.39795 27.1669 7.39795 27.0341V13.0219C7.40314 12.8661 7.46866 12.7185 7.58073 12.6101C7.69279 12.5018 7.84271 12.4413 7.99857 12.4414H31.3589C31.5128 12.4414 31.6605 12.5025 31.7694 12.6114C31.8782 12.7202 31.9394 12.8679 31.9394 13.0219V27.0341C31.9394 27.1669 31.8867 27.2941 31.7928 27.388C31.699 27.4818 31.5717 27.5346 31.439 27.5346ZM8.39882 26.5337H30.8985V13.4822H8.35875L8.39882 26.5337Z"
      fill="#1A1A1A"
    />
    <path
      d="M34.7818 33.5967H4.55538C4.31455 33.5938 4.07924 33.5252 3.87461 33.3981C3.66998 33.2711 3.50391 33.0906 3.39445 32.8761C3.27092 32.6705 3.20557 32.4353 3.20557 32.1955C3.20557 31.9558 3.27092 31.7204 3.39445 31.5149L5.3962 28.7324C5.4404 28.6678 5.50037 28.6155 5.57043 28.5804C5.64048 28.5454 5.71821 28.5289 5.79646 28.5323H33.3406C33.4191 28.5268 33.4979 28.5426 33.5683 28.5778C33.6387 28.613 33.6981 28.6663 33.7408 28.7324L35.7426 31.5149C35.8905 31.7072 35.9789 31.9386 35.9967 32.1805C36.0145 32.4224 35.9609 32.6642 35.8428 32.8761C35.7449 33.0788 35.5951 33.2522 35.4088 33.3787C35.2225 33.5052 35.0063 33.5804 34.7818 33.5967ZM6.15688 29.5932L4.31518 32.1555C4.27947 32.2012 4.2602 32.2576 4.2602 32.3157C4.2602 32.3737 4.27947 32.43 4.31518 32.4757C4.33535 32.5285 4.37113 32.5739 4.41756 32.6061C4.464 32.6382 4.51894 32.6556 4.57542 32.6559H34.8018C34.8617 32.6598 34.9214 32.6445 34.9719 32.6121C35.0223 32.5796 35.061 32.5318 35.0823 32.4757C35.1126 32.4278 35.1283 32.3723 35.1283 32.3157C35.1283 32.259 35.1126 32.2034 35.0823 32.1555L33.2204 29.5932H6.15688Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.8933 33.5966H13.9838C13.8988 33.5973 13.815 33.5756 13.7412 33.5334C13.6673 33.4912 13.6058 33.43 13.5633 33.3564C13.5194 33.2803 13.4963 33.194 13.4963 33.1061C13.4963 33.0183 13.5194 32.932 13.5633 32.8559L14.6443 30.8542C14.6875 30.7752 14.7513 30.7094 14.8288 30.6636C14.9063 30.6178 14.9946 30.5937 15.0846 30.5939H23.8323C23.9227 30.5914 24.0119 30.6146 24.0898 30.6606C24.1677 30.7067 24.2312 30.7737 24.2726 30.8542L25.3536 32.8559C25.3954 32.9322 25.4168 33.0182 25.4152 33.1052C25.4137 33.1921 25.3893 33.2772 25.3448 33.352C25.3004 33.4267 25.2372 33.4887 25.1616 33.5316C25.0859 33.5745 25.0003 33.5968 24.9133 33.5966H24.8933ZM14.8845 32.5957H24.1524L23.5923 31.5549H15.4451L14.8845 32.5957Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default CaseStudyDevelopment;
